.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #1a1c22;
  // background-color: lighten(#000, 93.5%);

  h1,
  .h1 {
    color: inherit;
  }

  p {
    margin-bottom: (30px / 2);
    font-size: ceil((14px * 1.5));
    font-weight: 200;
  }

  > hr {
    border-top-color: darken(lighten(#000, 93.5%), 10%);
  }

  .container &,
  .container-fluid & {
    padding-right: (30px / 2);
    padding-left: (30px / 2);
    border-radius: 6px; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    padding-top: (30px * 1.6);
    padding-bottom: (30px * 1.6);

    .container &,
    .container-fluid & {
      padding-right: (30px * 2);
      padding-left: (30px * 2);
    }

    h1,
    .h1 {
      font-size: ceil((14px * 4.5));
    }
  }
}
