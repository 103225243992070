.custom-darken-modal .modal-content {
  background-color: #2E303B;
  color: #fff;
}

.custom-darken-modal .modal-header, .custom-darken-modal .modal-footer {
  background-color: #2E303B;
  color: #fff;
}

.custom-darken-modal .modal-header {
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.custom-darken-modal .modal-title {
  color: #fff;
  position: relative;
  display: inline-block;
}

.custom-darken-modal .modal-title:after {
  content: "";
  display: block;
  width: 30%;
  height: 1px;
  background: #7B7B7B;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.user-manage-modal .modal-title:after {
  display: none !important;
}

.custom-darken-modal-impersonation {
  min-height: 450px;
}

.custom-darken-modal-user-impersonation {
  justify-content: space-between;
}

.custom-darken-modal--quick-impersonation-title {
  position: relative;
}

.custom-darken-modal--quick-impersonation-title::after {
  content: "";
  display: block;
  width: 30%;
  height: 1px;
  background: #7B7B7B;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.custom-darken-modal--impersonate-button {
  color: #fff;
  font-size: 18px;
  outline: none;
}

.custom-darken-modal--currently-impersonating {
  color: #fff !important;
  font-weight: 500;
  margin-bottom: 15px !important;
}

.custom-darken-modal .modal-body {
  padding-left: 25px;
  padding-right: 25px;
}

.custom-darken-modal .modal-footer {
  border-top: none;
}

.custom-darken-modal .modal-content .work-instructions-table > tr {
  color: #ffffff !important;
  border-top: none;
}

.custom-darken-modal .list-group-item {
  color: #fff !important;
  background-color: #2e303b !important;
  transition: 0.3s;
  &:hover {
    background-color: #434857 !important;
  }
}

.custom-darken-modal-title-text {
  font-weight: 400;
  margin-top: 3px;
  font-size: 17px;
  margin-bottom: 0;
  margin-left: 6px;

  &-title {
    max-width: 300px;
  }
}

.custom-darken-modal-button {
  color: rgb(255 255 255 / 70%);
  font-size: 25px;
  cursor: pointer;
  outline: none;
  transition: 0.3s;

  &:hover {
    color: #fff;
  }
}

.custom-darken-modal-search-button {
  font-size: 22px !important;
  margin-left: 10px;
}

.custom-darken-modal-impersonate-edit-name {
  display: none;
  cursor: pointer;
  color: rgb(255 255 255 / 70%);
  font-size: 12px !important;
  margin-left: 3px;
  margin-bottom: 0;
  outline: none;

  &:hover {
    color: #fff;
  }

  &-active {
    display: block;
  }
}

@media (min-width: 768px) {
  .custom-darken-modal-impersonate-edit-name {
    margin-left: 10px;
  }
}

@media (min-width: 991px) {
  .custom-darken-modal-impersonate-edit-name {
    margin-left: 3px;
  }
}

@media (min-width: 1200px) {
  .custom-darken-modal-impersonate-edit-name {
    margin-left: 10px;
  }
}

.custom-darken-modal--impersonation-full-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: .3s;
}

.profile-avatar-impersonation:hover .custom-darken-modal-impersonate-edit-name {
  display: block;
}

.custom-darken-modal-impersonate-button {
  color: rgb(255 255 255 / 70%);
  cursor: pointer;
  outline: none;
  transition: 0.3s;
  font-size: 15px;
  margin: 3px 0;

  &:hover {
    color: #fff;
  }

  &-set {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-end {
    color: #ff3c3c;

    &:hover {
      color: #b32424;
    }
  }
}

.custom-darken-modal-impersonate-loading-button {
  margin: 1px 0;
  font-size: 14px;
}

.custom-darken-modal-search-input-error {
  box-shadow: 0 0 0 0.25rem #dc354591 !important;
  color: #dc354591 !important;
}

.custom-darken-modal .input-group-addon,
.custom-darken-modal  .form-control {
  color: #fff !important;
  border-color: #585A60 !important;
  background: #434857 !important;
}

.custom-darken-modal-separator {
  background-color: #D0D2DA;
  width: 40%;
  margin: 15px auto;
}

.custom-darken-modal-custom-gutter {
  --bs-gutter-x: 3.5rem !important;
}

.custom-darken-modal-impersonation-custom-gutter {
  --bs-gutter-x: 1rem !important;
}

.custom-darken-modal--impersonation-full-name-input {
  outline: none;
  border: none;
  background-color: unset;
  color: #fff;
  font-weight: 600;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ffffff61;
}

.custom-darken-modal--card {
  border-color: #54595E !important;
}

.custom-darken-order-quote--card {
  background-color: #2E303B !important;
  border-color: #1AC98E !important;
}

.custom-darken-order-quote--card-header {
  background-color: #2E303B !important;
  border: none;

  &-content {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 7px;
  }
}

.custom-darken-order-quote--card-header-line {
  position: relative;

  &::after {
    background: #7b7b7b;
    bottom: -5px;
    content: "";
    display: block;
    height: 1px;
    left: 5px;
    position: absolute;
    width: 40% !important;
  }
}

.custom-darken-order-quote--body-content {
  .list-group-item {
    &:hover {
      background-color: unset !important;
    }
  }
}

.custom-darken-order-quote-summary {
  display: flex;
  align-content: center;
  justify-content: space-between;
  color: #C2C0C0;
  font-size: 13px;
}

.custom-darken-order-quote-summary-discount {
  color: #C2C0C0;
  margin-top: 15px;
  font-size: 13px;
  border: 1px dashed #ffc008;
  border-radius: 7px;
  padding: 10px;
}

.custom-darken-order-quote-summary-divider {
  border-bottom: 1px dashed #7B7B7B;
  width: 40%;
  margin: 15px 0;
}

.custom-darken-modal--card-lite {
  border-color: #1AC98E !important;
}

.custom-darken-order-quote--initial-body {
  background-color: #212529;
  color: #fff;

  & > p {
    margin-bottom: 0;
    padding: 0 10px;
  }
}

.custom-darken-order-quote--required-body {
  background-color: #2d303c !important;
  color: #fff;
  padding: 10px 20px !important;
}

.custom-darken-order-quote-card {
  background-color: #2E303B;
}

.custom-darken-order-quote--body {
  background-color: #2E303B;
}

.custom-darken-modal--card-header {
  background-color: #434857;
  color: #fff;
}

.custom-darken-modal--card-header-quotePreview {
  background-color: #2E303B;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 15px 0;
  border: none;
}

.custom-darken-modal--card-body {
  background-color: #2E303B;
}

.custom-darken-modal--quote-generated-body {
  padding: 10px 20px !important;
}

.custom-darken-modal--toggle-button-sm {
  color: #fff;
  font-size: 11px !important;
  line-height: 1 !important;
}

.custom-darken-modal--body-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 180px);
}

@media (min-width: 768px) {
  .custom-scroll {
    max-height: calc(100vh - 190px);
  }
}

@media (min-width: 992px) {
  .custom-scroll {
    max-height: calc(100vh - 200px);
  }
}


@media (min-width: 1200px) {
  .custom-scroll {
    max-height: calc(100vh - 210px);
  }
}

@media (min-width: 1400px) {
  .custom-scroll {
    max-height: calc(100vh - 220px);
  }
}

.custom-darken-switch {
  line-height: 0;
  margin-right: 6px;
}

.custom-darken-switch .form-check-input {
  width: 30px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  position: relative;
  appearance: none;
  background-color: #282a36;
  border-radius: 20px;
  border: 1px solid #ffffff;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-darken-switch .form-check-input::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s, background-color 0.3s;
}

.custom-darken-switch-green {
  color: #1AC98E;
}

.custom-darken-export-btn {
  border-radius: 7px;
  padding: 5px 10px;
  border-color: #1AC98E;
  color: #1AC98E;
}

.custom-darken-last-version-btn {
  border-radius: 7px;
  padding: 5px 10px;
}

.custom-darken-last-version-btn-wrapper {
  position: relative;
  display: inline-block;
}

.custom-darken-last-version-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 2px 6px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
}

.custom-darken-switch .form-check-input:checked {
  border-color: #1AC98E;
}

.custom-darken-switch .form-check-input:checked::before {
  transform: translateX(14px);
  background-color: #1AC98E;
}
