.profile-avatar {
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  padding: 7px;
  background-color: #2E303B;
  color: #fff;
  min-width: 36px;
  text-align: center;
  border: 1px solid #19A4D7;
  border-radius: 50%;
  letter-spacing: 1px;
  position: relative;
  transition: all 0.6s ease;

  &-sm {
    padding: 7px;
    min-width: 36px;
    max-width: 36px;
  }

  &-md {
    padding: 12px;
    min-width: 45px;
    max-width: 45px;
    font-weight: 500;
  }

  &:hover {
    background-color: #1481a970;
    border: 1px solid #b0b0b0;
  }

  &-full {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 6px 5px;
    transition: all 0.6s ease;
    padding: 10px;
    color: #fff;
    text-decoration: none !important;

    &:hover {
      color: #fff;
      background-color: #3C3F47;
      border-radius: 6px;
    }

    &-email {
      font-weight: 200;
    }

    &-name {
      font-weight: 600;
    }

    &-details {
      margin-left: 10px;
    }
  }

  &-not-interactive {
    pointer-events: none !important;
  }

  &-impersonation {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.6s ease;
    color: #fff;
    text-decoration: none !important;
    padding: 10px 15px !important;
    max-width: max-content;
    width: 100%;
    margin-right: 15px;
    background-color: #434857;
    border-radius: 6px;
    max-height: 62px;

    &:hover {
      color: #fff;
      background-color: #3C3F47;
      border-radius: 6px;
    }

    &-active {
      background-color: #4c5671b0;
    }

    &-container {
      max-width: 70%;
    }

    &-favorite {
      max-width: 100%;
      justify-content: space-between;
    }
  }

  &-favorite-users {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    padding: 0;
  }

  &-separator {
    flex-shrink: 0;
    background-color: #D0D2DA;
    width: 1px;
    height: 30px;
    max-height: 36px;
    align-self: center;
    margin: 0 16px;
  }

  &-favorite-active {
    color: #E4D837 !important;
    &:hover {
      color: #B8AD1A !important;
    }
  }

  &-active {
    background-color: #1481a970;
    border: 1px solid #b0b0b0;
  }
}

@media (max-width: 991px) {
  .profile-avatar-favorite-users {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

@media (min-width: 991px) {
  .profile-avatar-separator {
    margin: 0 10px;
  }
}

@media (min-width: 1200px) {
  .profile-avatar-separator {
    margin: 0 16px;
  }
}

.profile-menu {
  cursor: default !important;
  background-color: #2E303B;
  border-radius: 10px;
  position: fixed;
  top: calc(0px + 50px);
  right: 2%;
  min-width: 200px;
  max-width: 265px;

  &-separator {
    background-color: #D0D2DA;
    width: 30%;
    margin: auto auto 5px auto;
  }

  &-items {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &-item {
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin: 6px 15px;
    text-decoration: none;
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    transition: all 0.6s ease;

    &:hover {
      background-color: #3C3F47;
      text-decoration: none;
      color: #fff;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      margin-right: 7px;
    }

    &-end-impersonation:hover {
      color: #dc3545 !important;
    }

    &-signout {
      cursor: pointer;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      margin: 30px 15px 0 15px;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      transition: all 0.6s ease;
      color: #A0A0A0;

      &:hover {
        text-decoration: none;
        color: #dc3545;
      }
    }
  }
}
