.create-new-run-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-width: 640px;
  height: 700px;
  max-height: 750px;
  padding: 25px 40px;
  background-color: #2E303B;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 24%);
  z-index: 1050;
  border-radius: 3px;

  &-tip {
    padding: 0 30px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 200;
  }

  &-header {
    text-align: center;
    color: #fff;
    margin-bottom: 15px;

    & > p:first-child {
      font-size: 24px;
      font-weight: 400;
    }

    & > p:last-child {
      font-size: 20px;
      font-weight: 100;
    }
  }
}

.create-new-run-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 1040;
}

.create-new-run-modal-loader {
  width: 135px;
  height: 70px;
  box-shadow: 0 4.5px 0 #fff;
  position: relative;
  clip-path: inset(-60px 0 -7.5px);
}

.create-new-run-modal-loader:before {
  content: "";
  position: absolute;
  inset: auto calc(50% - 25.5px) 0;
  height: 75px;
  //noinspection CssInvalidFunction
  --g:no-repeat linear-gradient(#ccc 0 0);
  background: var(--g),var(--g),var(--g),var(--g);
  background-size: 24px 21px;
  animation:
          l7-1 2s infinite linear,
          l7-2 2s infinite linear;
}

@keyframes l7-1 {
  0%,
  100%  {background-position: 0 -75px,100% -75px}
  17.5% {background-position: 0 100%,100% -75px,0 -75px,100% -75px}
  35%   {background-position: 0 100%,100% 100% ,0 -75px,100% -75px}
  52.5% {background-position: 0 100%,100% 100% ,0 calc(100% - 24px),100% -75px}
  70%,
  98%  {background-position: 0 100%,100% 100% ,0 calc(100% - 24px),100% calc(100% - 24px)}
}

@keyframes l7-2 {
  0%,70% {transform:translate(0)}
  100%  {transform:translate(200%)}
}


