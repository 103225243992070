.ResourceCard {
  background: #3B3F4B;
  border: 1px solid #434857;
  border-radius: 5px;
  padding: 20px;

  &List {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) !important;

    &Cards {
      margin-top: 15px;
    }

    &Location {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &SubLocation {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  &Resources {
    display: flex;
    height: 100%;
    max-height: 37px;

    &Data {
      max-width: 100px;
    }
  }

  &Printer {

    &Status {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: right;
      white-space: nowrap;
    }

    &Name {
      width: 130px;
      max-width: 130px;
    }

    &Type {
      width: 130px;
      max-width: 130px;
      font-size: 12px;
    }
  }

  &ModelerStatuses {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    max-height: 37px;
  }

  &TopView {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &BottomView {
    display: flex;
    justify-content: space-between;

    &Card {
      background: #434857;
      padding: 10px;
      border-radius: 5px;
    }
  }

  &Material {
    &Name {
      width: 115px;
      max-width: 115px;
    }
  }

  &Batch {
    &Quantity {
      max-width: 85px;
      display: inline-block;
    }
  }

  &CurrentRunNameShort {
    max-width: 165px;
  }

  &CurrentRunNameLong {
    max-width: 300px;
  }

}
